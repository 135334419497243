import { getLodop } from './CLodopFuncs' //这块就是引用的上面一大坨暴露出来的方法喽
import { Modal } from 'ant-design-vue'
const confirm = Modal.confirm
import Antd from 'ant-design-vue';
import router from '@/router/index'

const PrintAccount = (qrTitle, url, type) => {
    
    if(!CheckIsP()){
        return;
    }
    // 获取默认选择的打印机通过缓存配置得
    var index='-1';
    let count = LODOP.GET_PRINTER_COUNT();
    var printer=localStorage.getItem("printox");
    for (let i = 0; i < count; i++) {
    	//根据设备序号获取设备名
    	let msg = LODOP.GET_PRINTER_NAME(i);
    	if(msg==printer){
    		index=i;
    	}
    }
    if(index=='-1'){
        Message.error('请选择打印机')
    	return;
    }
    var ifprint=LODOP.SET_PRINTER_INDEXA(index);
    
    
   
    if(typeof url == 'string'){
        // 单个打印
        LODOP.NewPage();
         LODOP.ADD_PRINT_URL(0, 0, "100%", "100%", url);
        console.log('打印地址:'+url)
    }else{
        // 批量打印
        for(var i=0;i<url.length;i++){
            LODOP.NewPageA();
            LODOP.ADD_PRINT_URL(0, 0, "100%", "100%", url[i]);
            // if(imgurl=='hasimg'){
            //      LODOP.ADD_PRINT_IMAGE(10, '5%', 80, 80,`<img border='0' src="http://mtx.znsyun.com/site/qrcode?url='${url[i]}" />`)
            // }
            console.log('打印地址:'+url)
        }
    }
    // 只针对分拣标签打印尺寸
    LODOP.SET_PRINT_PAGESIZE(1,'59mm','39mm','tag');
    LODOP.SET_PRINT_STYLEA(0, "HOrient", 3);
    LODOP.SET_PRINT_STYLEA(0, "VOrient", 3);
    // LODOP.SET_PRINT_PAGESIZE(2, 0, 0, "A4");
    LODOP.SET_LICENSES("", "35F23B8E8767C5370C215E7185909888AB3", "23580B189D96F33252722697A57AC00506B", "");
    if(type=='preview'){
        LODOP.PREVIEW();
    }else{
        LODOP.PRINT();
    }
}
const CheckIsInstall = () => {
    var LODOP = getLodop();
    LODOP.SET_LICENSES("", "35F23B8E8767C5370C215E7185909888AB3", "23580B189D96F33252722697A57AC00506B", "");
            try {
                if (LODOP.VERSION) {
                    if (LODOP.CVERSION)
                        alert("当前有C-Lodop云打印可用!\n C-Lodop版本:" + LODOP.CVERSION + "(内含Lodop" + LODOP.VERSION + ")");
                    else
                        alert("本机已成功安装了Lodop控件！\n 版本号:" + LODOP.VERSION);
                }
            } catch (err) {
                alert(err);
            }
}
const CheckIsP = () => {
    var LODOP = getLodop();
    LODOP.SET_LICENSES("","35F23B8E8767C5370C215E7185909888AB3","23580B189D96F33252722697A57AC00506B","");
        if (LODOP.VERSION) {
            var localStorageI=localStorage.getItem("printox");
            if(!localStorageI){
            	confirm({
            	        content: '当前打印方式未选择打印机，是否跳转打印配置页面',
                        okText:'确定',
                        cancelText:'取消',
            	        onOk() {
            	         setTimeout(()=>{
                             router.push('/printer')
                         },200)
            	        },
            	        onCancel() {
            			},
            			
            	 });
            	return false;
            }else{
            	return true
            }
        } else {
            confirm({
                    content: '系统检测到您未安装打印插件，是否跳转打印配置页面去下载',
                    okText:'确定',
                    cancelText:'取消',
                    onOk() {
                     setTimeout(()=>{
                         router.push('/printer')
                     },200)
                     return false
                    },
                    onCancel() {
                        return false
            		},
             });
        }
}

export { PrintAccount,CheckIsP,CheckIsInstall }