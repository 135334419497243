<template>
  <div class="logo-top-img">
      <img class="logo-img" src="@/assets/login/logo.png" @click="go_home">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: ''
  },
  methods: {
      go_home(){
          this.$router.back('/')
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
   .logo-top-img{
       width: 100%;
       height:130px;
       background: #FFFFFF;
       box-shadow: 0px 2px 11px 0px rgb(177 188 199 / 15%);
       text-align: center;
       display: flex;
       justify-content: center;
       align-items: center;
       .logo-img{
           width:77.5%;
           
       }
   }
</style>
